<template>
    <div class="container intro">
        <div class="col-wrap header">
            <div class="bg bg-animation2"></div>
            <div class="wrap-content">
                <Nav></Nav>
                <div class="content">
                    <h3>ABOUT ITRAX</h3>
                    <h1>让品牌有情怀，让营销有温度</h1>
                    <p>Considerate Branding for Effective Marketing</p>
                </div>
            </div>
        </div>

        <div class="col-wrap des">
            <div class="wrap-content">
                <a id="intro"></a>
                <div class="content">
                    <div class="col-title">
                        <h1>公司介绍</h1>
                        <div>Company Profile</div>
                    </div>
                    <div class="content summary-content" v-html="about.summary"></div>
                    <div class="col-title">
                        <h1>行业地位</h1>
                        <div>Industrial Position</div>
                    </div>
                    <div class="rank">
                        <img :src="resourceBaseUrl + about.positionPc" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-wrap honor">
            <div class="bg"></div>
            <div class="wrap-content">
                <a id="honor"></a>
                <div class="content">
                    <div class="col-title">
                        <h1>公司荣誉</h1>
                        <div>Honors and Rewards</div>
                    </div>
                    <div class="content" style="padding-bottom: 30px;">
                        <ul>
                            <li v-for="item in honor" :key="item.id">
                                <img :src="resourceBaseUrl + item.cover + '?x-oss-process=image/resize,m_fill,h_500,w_500'" />
                                <div class="mask">
                                    <h4>{{ item.title }}</h4>
                                    <p>{{ item.desc }}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-wrap ">
            <div class="content">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
import settings from "@/settings";
export default {
    name: "pc-intro",
    components: { Nav, Footer },
    data() {
        return {
            resourceBaseUrl: settings.resourceBaseUrl,
            honor: [],
            about: {},
        };
    },
    mounted() {
        this.loadSummary();
        this.loadHonor();
    },
    methods: {
        loadSummary() {
            this.$http.get("/api/about/summary").then((res) => {
                this.about = res.data;
            });
        },
        loadHonor() {
            this.$http.get("/api/honor/list").then((res) => {
                this.honor = res.data;
                setTimeout(() => {
                    if (this.$route.hash == "#honor") {
                        var anchor = this.$el.querySelector(".honor");
                        //console.warn(anchor.offsetTop);
                        document.documentElement.scrollTop = anchor.offsetTop;
                    }
                }, 100);
            });
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/pc.css");
.summary-content {
    color: #333;
    font-size: 17px;
    line-height: 28px;
    padding-bottom: 30px;
}
.honor .bg {
    background: url("~@/assets/pc/intro/honor-bg.jpg") no-repeat center bottom;
    padding-bottom: 100px;
}
.honor .col-title {
    border-color: #fff;
}
.honor .col-title * {
    color: #fff;
}
.honor .content {
    margin: 50px 0;
}

.honor .content ul li img {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.honor .content ul li .mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 54, 130, 0);
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: scale(0);
}
.honor .content ul li .mask * {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0;
}
.honor .content ul li:hover img {
    transform: scale(1.1);
}
.honor .content ul li:hover .mask {
    background: rgba(0, 54, 130, 0.9);
    transform: scale(1);
}
.honor .content ul li:hover .mask * {
    opacity: 1;
}

.des .rank {
    text-align: center;
    margin: 70px 0;
}
.des .wrap-content {
    margin: 80px auto;
}
.des .content {
    margin-bottom: 100px;
}

.des .content p b {
    color: #222;
}
.des {
    background: url("~@/assets/pc/intro/intro-bg.jpg") no-repeat center bottom;
    background-size: 100% auto;
}

.header .wrap-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.header .wrap-content .content {
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (max-width: 1440px) {
    .intro .header .bg {
        background: url("~@/assets/pc/intro/banner-bg.jpg") no-repeat center bottom;
    }

    .header .wrap-content .content h3 {
        font-size: 24px;
    }
    .header .wrap-content .content h1 {
        font-size: 30px;
        margin: 15px 0;
    }
    .header .wrap-content .content p {
        font-size: 20px;
    }
    .des .content p {
        color: #333333;
        font-size: 16px;
        margin: 20px 0 20px 25px;
        line-height: 30px;
        text-indent: 32px;
    }
    .des .content,
    .honor .content {
        margin: 0 auto;
    }
    .des .rank img {
        width: 800px;
    }

    .honor .content ul li {
        position: relative;
        display: inline-block;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        overflow: hidden;
        margin: 0 15px 15px 0;
        width: 205px;
        height: 205px;
    }

    .honor .content ul {
        margin-left: 25px;
        margin-bottom: 50px;
    }

    .honor .content ul li .mask h4 {
        margin: 0 15px;
        font-size: 14px;
    }
    .honor .content ul li .mask p {
        font-size: 12px;
        line-height: 16px;
        margin: 15px 15px;
        text-align: center;
    }
}

@media only screen and (min-width: 1441px) {
    .intro .header .bg {
        background: url("~@/assets/pc/intro/banner-bg.jpg") center bottom;
        height: 760px;
    }
    .header .wrap-content .content h1 {
        font-size: 30px;
        margin: 15px 0;
    }
    .header .wrap-content .content h3 {
        font-size: 24px;
    }
    .header .wrap-content .content p {
        font-size: 20px;
    }
    .des .content p {
        color: #333333;
        font-size: 16px;
        margin: 15px 0 15px 25px;
        line-height: 30px;
        text-indent: 32px;
    }
    .des .content,
    .honor .content {
        width: 1280px;
        margin: 0 auto;
    }

    .honor .content ul li {
        position: relative;
        display: inline-block;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        overflow: hidden;
        margin: 0 15px 15px 0;
        width: 268px;
        height: 268px;
    }

    .honor .content ul {
        margin-left: 25px;
    }

    .honor .content ul li .mask h4 {
        margin: 0 15px;
    }
    .honor .content ul li .mask p {
        font-size: 14px;
        line-height: 22px;
        margin: 15px 15px;
        text-align: center;
    }
}
</style>
